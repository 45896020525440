import "./styles.css";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navigator from "./Navigator/Navigator";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Gigs from "./Pages/Gigs";


export default function App() {

  const SwipeTransition = ({ children }) => (
    <div className="swipe-transition">{children}</div>
  );

  return (
    <Router>
    <div className="App">
      <div className="navigatorContainer"> <Navigator /> </div>
        <div className="pageContainer">
          <Routes>
            <Route path="/" element={<SwipeTransition><Home />   </SwipeTransition>} />
            <Route path="/Home"    element={<SwipeTransition><Home />   </SwipeTransition>} />
            <Route path="/Contact" element={<SwipeTransition><Contact /></SwipeTransition>} />
            <Route path="/Gigs" element={<SwipeTransition>   <Gigs />   </SwipeTransition>} />
          </Routes>
        </div>
    </div>
    </Router>
  );
}
