import React from "react";
import "./LandingPage.css";

function LandingPage() {
  return (
    <div className="container-fluid">
      <div className="row topSection">
        {/* <div className="column  col-md-6">
          <h1 className="goldloxeTitle">Goldloxe </h1>
        </div> */}
        {/* <div className="column col-md-6"> */}
        <div className="bio">
          <div className="bioText">
             flounder are a five piece, South London rock band, fronted by Rob Johnston, guitars by Adam Gilleard and Cal Bakaya, bass by Finlay McBride and drums by Jack Grint.
            {/* FLOUNDER ARE A FIVE PIECE, SOUTH LONDON ROCK BAND. FRONTED BY ROB JOHNSTON, GUITARS BY ADAM GILLEARD AND CAL BAKAYA, BASS BY FINLEY MCBRIDE AND DRUMS BY JACK GRINT. */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
