import React from "react";

import Promo from "./FlounderClips.mp4";
import "./VideoInTv.css";


function GoldloxePromo() {
  const media = document.querySelector("video");

  function togglePlay() {
    if (media.paused) media.play();
    else media.pause();
  }

  return (
    <div className="video-poster-container">
      <img
        className="background-image"
        src="https://purepng.com/public/uploads/large/purepng.com-old-televisiontvtelecommunicationmonochromeblack-and-whittelevisionoldblack-and-white-1421526535629pyfty.png"
        alt="Background"
      />
      <div className="video-wrapper">
        <video
          className="video-element"
          src={Promo}
          muted
          loop
          autoPlay
        >
        </video>
      </div>
    </div>
    // <div className="videoContainer">
    //   <video onClick={togglePlay} src={Promo} width="90%" autoPlay muted loop />
    // </div>
  );
}

export default GoldloxePromo;
