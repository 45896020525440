// Import necessary dependencies
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Navigator.css";

// Component for the hamburger icon and fold-out selector
const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const menuOpenStyle = {
    width: "70px",
    height: "10px"
  };
  const menuClosedStyle = {
    width: "30px",
    height: "30px"
  }

  const handleMenuToggle = (x) => {
    if (x === 1) {
      setMenuOpen(true);
    }
    else {
      setMenuOpen(false);
    }

  };

  const handlePageChange = (page) => {
    // Add logic to navigate to the selected page (e.g., using React Router)
    console.log(`Navigating to ${page}`);
    // For now, let's just close the menu
    setMenuOpen(false);
  };



  return (
      <div className="navigation">
      <div className="HamburgerContainer" style={menuOpen ? (menuOpenStyle) : (menuClosedStyle)} onMouseEnter={() => {handleMenuToggle(1)}}>
        <div className="strip1"> </div>
        <div className="strip2"> </div>
        <div className="strip1"> </div>
        <div className="strip2"> </div>
        <div className="strip1"> </div>
      </div>

      {menuOpen && (
        <div className="fold-out-menu" onMouseLeave={() => {handleMenuToggle(0)}}>
          <Link to="/Home" className="menu-item" onClick={() => handlePageChange('Page 1')}>
            Home
          </Link>
          <Link to="/Contact" className="menu-item" onClick={() => handlePageChange('Page 2')}>
            Contact
          </Link>
          <Link to="/Gigs" className="menu-item" onClick={() => handlePageChange('Page 3')}>
            Gigs
          </Link>
        </div>
      )}
      </div>


  );
};

export default Navigation;
