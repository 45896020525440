import React from 'react';

import LandingPage from "../LandingPage/LandingPage";
import VideoInTv from "../VideoInTv/VideoInTv";
import Navigator from "../Navigator/Navigator";

import "../styles.css";

function Home() {
  return (
    <div className="">
      <h1>flounder </h1>
      {/* GOLD LOXE BANNER */}
      {/* <ScrollingBanner message="GOLDLOXE NEW SITE COMING SOON " /> */}
      <div className="Promo">
        <VideoInTv />
      </div>
      {/*  Header Section  */}
      <div className="titleBio">
        <LandingPage />
      </div>
    </div>
  );
}

export default Home;
