import React from "react";
import CavGig from "./Monday_29th.jpg";
import "./Gigs.css";
import "../styles.css";

function Gigs() {
  return <div className="">
  <h1> Gigs </h1>
    <h3> Monday 29 th Jan</h3>
    <div >
      <img className="gigImage" src={CavGig} />
      <div className="fill"> </div>
    </div>

  </div>
}

export default Gigs;
