import React from "react";
import "../styles.css";
function Contact() {
  return <div>
    <h1> flounder </h1>
    <h2> email us:</h2>
    <h3> flounderband1@gmail.com</h3>
    <h2> floundergram us: </h2>
    <h3> @flounder_band_ </h3>
    <div className="fill"> </div>
   </div>
}

export default Contact;
